
function hashCode(str) {
    if( str == null || str.length === 0)
      return "";
  
    var hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
      chr   = str.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString();
  }
  
  function hostCacheBustingHash() {
    return hashCode(window.location.hostname);
  }
  
  function timedCacheBustingHash(timeoutMins) {
    const timeout = (timeoutMins < 1 ? 1 : timeoutMins)*60*1000;
    const str = window.location.hostname + Math.floor(Date.now() / timeout);
    return hashCode(str);
  }
  
  export { timedCacheBustingHash, hostCacheBustingHash };