import { registerApplication } from "single-spa";
import singleSpaHtml from "single-spa-html";
import router from "./router";
import { hostCacheBustingHash } from "./cacheBusting";
import pubsub from "@signicat/pubsub";

function registerApp(element) {
  addAppDomElement(element.name, element.container);
  const app = appBuilder(element);
  registerApplication({
    name: element.name,
    // eslint-disable-next-line no-undef
    app: app,
    activeWhen: router(element.route),
    customProps: {
      name: element.name,
      host: element.host,
      route: element.route,
      manifest: element.data,
      store: pubsub,
      provider: element.provider,
    },
  });
}

function appBuilder(element) {
  const entryPointUrl = cacheBustedFilename(element.host, element.data);
  if (element.data.html) {
    return singleSpaHtml({
      template: async () => (await fetch(entryPointUrl)).text(),
    });
  }
  // eslint-disable-next-line no-undef
  return () => System.import(entryPointUrl);
}

function cacheBustedFilename(host, manifest) {
  let file = manifest.mainjs;
  if (manifest.html) file = manifest.html;
  const fileUrl = new URL(file, host);

  return fileUrl.href + "?v=" + hostCacheBustingHash();
}

function addAppDomElement(name, container) {
  let spaAppElementId = "single-spa-application:" + name;

  if (document.getElementById(spaAppElementId)) return; // Bail out if element already exists

  let spaAppDomElement = document.createElement("div");
  spaAppDomElement.setAttribute("id", spaAppElementId);
  document.getElementById(container).appendChild(spaAppDomElement);
}

export default registerApp;
