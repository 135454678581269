import { loader } from "./loader";

function isLoading(loading) {
  document.getElementById("initial-loading").style.display = loading
    ? "block"
    : "none";
}

function registerApps(apps) {
  var loaded = apps.map((app) => {
    return loader(
      app.name,
      app.host,
      app.route,
      app.container ? app.container : "apps",
      app.provider ? app.provider : ["*"]
    );
  });
  return Promise.allSettled(loaded); // Wait for all promises to finish, either resolved or rejected.
}

export { isLoading, registerApps };
