import { addPrime } from "./addPrime";
import { addAnalytics } from "./addAnalytics";
import { start } from "single-spa";
import pubsub from "@signicat/pubsub";
import { registerApps, isLoading } from "./utils";
import updateApps from "./updateApps";
import "animate.css";
import "src/styles/index.css";
import initializeSentry from "./initializeSentry";
import { getConfiguration } from "@signicat/signicat-mfes-config-mapping";

function startApplication() {
  isLoading(true);

  getConfiguration(window.location.origin, "/root-config.json").then((data) => {
    initializeSentry(data.sentry);

    const apps = data.apps;
    document.head.appendChild(
      addPrime(window.location.origin, data.styleVersion)
    );
    addAnalytics(document, data.gaTag);
    const globalApps = apps.filter((app) => app.isGlobal);
    const otherApps = apps.filter((app) => !app.isGlobal);

    registerApps(globalApps)
      .then(() => {
        updateApps();
        start({
          urlRerouteOnly: true,
        });
        return registerApps(otherApps, "apps");
      })
      .then(() => {
        pubsub.emitLatestEvent("scope", updateApps);
        pubsub.subscribe("scope", updateApps);
      });
  });
}

startApplication();
