import { mapUrlToConfig } from "@signicat/signicat-mfes-config-mapping";
function cdnHost(url) {
  url = new URL(url);
  return url.hostname === "localhost"
    ? "https://static.signicat.dev"
    : url.origin;
}

function addPrime(configMapping, styleVersion) {
  let link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href =
    cdnHost(mapUrlToConfig(configMapping)) +
    "/prime-assets/styles-" +
    styleVersion +
    ".css";
  return link;
}

export { addPrime };
