import { unregisterApplication, getAppNames } from "single-spa";
import checkProvider from "./contexts/checkProvider";
import registerApp from "./registerApp";
import { isLoading } from "./utils";
import { allApps } from "./loader";

function updateApps() {
  isLoading(true);
  let registeredApps = getAppNames();
  allApps.forEach((element) => {
    if (registeredApps.indexOf(element.name) !== -1) {
      // apps which are registered but should not be
      if (!checkProvider(element.provider)) {
        unregisterApplication(element.name);
      }
    } else {
      // apps which are not registered but should be
      if (checkProvider(element.provider)) {
        registerApp(element);
      }
    }
  });
  isLoading(false);
}

export default updateApps;
